.breadcrumb {
  height: 0.7rem;
  display: flex;
  align-items: center;
}

.breadcrumb * {
  font-size: 1em;
  flex-shrink: 0;
}

:deep(.ant-breadcrumb) {
  display: flex;
  align-items: center;
}

:deep(.ant-breadcrumb) .ant-breadcrumb-link,
:deep(.ant-breadcrumb) > span {
  display: flex;
  align-items: center;
}

:deep(.ant-breadcrumb) .ant-breadcrumb-link:last-of-type,
:deep(.ant-breadcrumb) > span:last-of-type {
  flex: 1;
}

:deep(.ant-breadcrumb) .dot {
  width: 100%;
  max-width: 4rem;
}

.white {
  opacity: 0.5;
  color: #fff;
  font-size: 1em;
}

.white svg {
  color: #fff;
}

.white a {
  color: #fff;
  display: flex;
  align-items: center;
}

.white :deep(.ant-breadcrumb-link),
.white :deep(.ant-breadcrumb-separator) {
  color: #fff;
}

.grey {
  color: #999;
  border-bottom: 0.01rem solid #e5e5e5;
}

.grey svg {
  color: #bbb;
}

.grey a {
  color: #999;
  display: flex;
  align-items: center;
}

.grey :deep(.ant-breadcrumb-link),
.grey :deep(.ant-breadcrumb-separator) {
  color: #999;
}

:deep(.ant-breadcrumb-link) a:hover {
  color: #00ffc0 !important;
}

.icon_home {
  font-size: 1.25em;
  margin-right: 0.1rem;
}

:deep(.ant-breadcrumb-separator) {
  font-size: 0.12rem;
}