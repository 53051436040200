/deep/ .breadcrumb {
  border-bottom: 0;
}

.feedback {
  padding-bottom: 1.1rem;
}

.title {
  text-align: center;
  padding-top: 0.2rem;
  padding-bottom: 0.35rem;
}

.title h1 {
  font-size: 0.32rem;
  color: #333333;
  padding-bottom: 0.15rem;
  position: relative;
  margin-bottom: 0.25rem;
}

.title h1::after {
  content: '';
  width: 0.4rem;
  height: 0.05rem;
  border-radius: 0.03rem;
  background-color: #00ffc0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.title h2 {
  font-size: 0.18rem;
  color: #555555;
  line-height: 1.667;
}

.form {
  border-radius: 0.2rem;
  background-color: #f5f5f5;
  padding: 0.6rem 1rem 0.5rem;
}

.form /deep/ .ant-form-item {
  margin-bottom: 0.3rem;
}

.form /deep/ .ant-form-item-with-help {
  margin-bottom: 0.07rem;
}

.form /deep/ .ant-form-item-label {
  font-size: 0.2rem;
  color: #333333;
  line-height: 1;
  margin-bottom: 0.2rem;
}

.form /deep/ .ant-form-item-label label {
  font-size: 0.2rem;
  color: #333333;
  display: flex;
  flex-direction: row-reverse;
}

.form /deep/ .ant-form-item-label label::after {
  display: none;
}

.form /deep/ .ant-input {
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.03rem;
  padding: 0 0.2rem;
  font-size: 0.18rem;
}

.form /deep/ .ant-input-disabled {
  background-color: #eee;
  border-color: #eee !important;
}

.form-item.w2 {
  display: flex;
  justify-content: space-between;
}

.form-item.w2 .ant-form-item {
  width: calc(50% - 0.4rem);
}

textarea.ant-input {
  line-height: 2 !important;
  padding: 0.2rem;
}

/deep/ .ant-select-selection {
  background-color: #fff;
  line-height: 0.6rem;
  height: 0.6rem !important;
}

/deep/ .ant-select-selection__rendered {
  line-height: 0.58rem !important;
}

/deep/ .ant-upload {
  border-color: transparent !important;
  background-color: #eeeeee !important;
}

/deep/ .ant-upload:hover {
  border-color: #00ffc0 !important;
}

/deep/ .ant-upload-btn {
  padding: 0.8rem 0.3rem 0.7rem !important;
}

/deep/ .ant-upload-btn h2 {
  font-size: 0.24rem;
  color: #888888;
  line-height: 1.25;
  margin-bottom: 0.08rem;
}

/deep/ .ant-upload-btn .des {
  font-size: 0.18rem;
  color: #333333;
  line-height: 1.667;
}

.submit {
  text-align: center;
}

.submit .ant-btn {
  min-width: 4rem;
  padding: 0 0.4rem;
  height: 0.6rem;
  line-height: 0.6rem;
  border-radius: 0.3rem;
  background-color: #00ffc0;
  color: #000;
  font-size: 0.24rem;
  border-color: #00ffc0;
}

.submit .ant-btn:hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  box-shadow: 0 0 0.1rem 0 rgba(121, 249, 217, 0.8);
}

.has-error .ant-select-selection:hover {
  box-shadow: none;
}

/deep/ .ant-form-item-children {
  position: relative;
}

.img_loading {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.04rem;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.img-list {
  display: flex;
  margin-bottom: 0.3rem;
}

.img-list .img {
  width: 1.2rem;
  height: 1.2rem;
  border: 0.01rem solid #d9d9d9;
  padding: 0.08rem;
  border-radius: 0.02rem;
  position: relative;
  cursor: pointer;
  margin-right: 0.1rem;
}

.img-list .img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.img-list .img .handle {
  position: absolute;
  width: 1.02rem;
  height: 1.02rem;
  left: 0.08rem;
  top: 0.08rem;
  background-color: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.18rem;
  color: #fff;
  opacity: 0;
  transition: .3s;
}

.img-list .img .handle:hover {
  opacity: 1;
}

.img-list .img .handle .anticon {
  opacity: 0.8;
  margin: 0 0.05rem;
}

.img-list .img .handle .anticon:hover {
  opacity: 1;
}

.img-list .uploading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.img-list .uploading span {
  margin-top: 0.1rem;
}

.input {
  position: relative;
}

.input .limit {
  position: absolute;
  right: 0.1rem;
  bottom: 0;
  color: #999;
}

.input .ant-input {
  padding-bottom: 0.3rem;
}

/* fade-transform */

.img-transform-leave-active,
.img-transform-enter-active {
  transition: all .5s;
}

.img-transform-enter {
  opacity: 0;
}

.fade-transform-leave-to {
  opacity: 0;
}